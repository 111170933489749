var Unity3d = (function () {

    // var $templateTrueconfItem;
    var unityInstance;
    var currentSceneID;
    var $templateUnity3dItem;
    var keypress_additionalData = "showexit:1";
    var unityvideoplayer;
    var posterToOpen;

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function Init() {
        //Build();
        sort();
        renderList();
        unityvideoplayer = videojs('unity_video');
    }

    function sort() {
        AppData.unity3d.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function renderList() {
        var
            $self = $('#unity3d .unity3dList'),
            $templateUnity3dItem = $('template#tplUnity3dItem').contents(),
            $fragmentUnity3d = $(document.createDocumentFragment());
        $.each(AppData.unity3d, function (i, el) { // active if!!!
            if(Boolean(el.active)) {
                var $clone = $templateUnity3dItem.clone();
                $clone.attr('data-id', el.u3did);
                $clone.find('.data-title').html(el.title);
                $clone.appendTo($fragmentUnity3d);
            }
        });
        $self.html($fragmentUnity3d);
        $('#unity3d').find('.no-result').toggle(Boolean(!AppData.unity3d.length));
    }

    function callbackHandler(functionName, json) {
        if (typeof Unity3d[functionName] === 'function') {
            Unity3d[functionName](JSON.parse(json));
        }
    }

    function load3DPlayer(sceneID, unity_url) {
        $("#unity3d-details").show();
        $("#unity3d-details .loader").show();
        //$('#unity3d .end3d').hide();

        currentSceneID = sceneID;
        from3d = unity_url ? unity_url : AppData.settings.unity_url;

        if(unityInstance !== undefined){
            unityInstance.SendMessage('Logic', 'LoadScene', sceneID);
            loadPosters();
        }else{
            UnityLoader.Error.handler = function (e, t) {
                console.log(e);
            }

            unityInstance = UnityLoader.instantiate("unityContainer", unity_url ? unity_url : AppData.settings.unity_url, {
                onProgress: function(unityInstance, progress) {
                    let percentage = Math.round(progress * 100);
                    $('#unity3d-details .loader .percentage-line').css({ width: percentage+'%'});
                },
            });
        }
    }

    let letCoffeeCheck = true;
    let checktimer = 1000;

    //"coffee_01": "meeting6.rentit.hu/c/6489664628",
    let rooms = {
    };

    function checkCoffee() {
        if (letCoffeeCheck && Object.keys(rooms).length > 0) {

            letCoffeeCheck = false;
            setTimeout(function (){
                letCoffeeCheck = true;
            }, checktimer);

            checktimer = 10000;

            for (let i in rooms) {
                checkTrueConfRoom(rooms[i], function(response) {
                    let participants = response.participants;
                    console.log(i+"|Active users\n"+participants.length);
                    unityInstance.SendMessage('Logic', 'UpdateString', i+"|Active users\n"+participants.length);
                });
            }
        }
    }

    function entered(data) {
        console.log('Entered', data);

        if (data.door == "coffe_approach") {
            checkCoffee();
            return;
        }

        if (data.area == "chat_corner") {
            unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');

            closeTrueConfCall("3d");
            openTrueConfCall(rooms[data.door], 'exhibitor');
            return;
        }

        if (data.area == "pdf") {
            unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');

            $("#unity3d-details .open_new_window").show();
            posterToOpen = data.door;

            return;
        }

        if (currentSceneID.indexOf("default") > -1) {
            unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');
            //checkTrueConfRoom(data.door, function(response) {

                var url = AppData.settings.infopanel_url + '/' + data.door;
                url += "?pid="+encodeURIComponent(btoa(AppData.profile.id));

                $('#unity3d-details #stand_viewer').attr('src', url);
            //});
            $('#unity3d-details .stand_viewer').css("display", "flex").hide().fadeIn(400, function () {
                $("#unity3d-details .reactivate").show();
            });

            //call_exhibitor(data.door);

        } else if (currentSceneID == "poster_hall") {

            unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');

            if (data.door.indexOf("video") > -1) {
                unityvideoplayer.reset();

                switch (data.door) {
                    case "video_01":
                        unityvideoplayer.src('https://streaming2.rentit.hu/vod/_definst_/FISU/FISU_HALL_VIDEOS/Budapest_Visszavar.mp4/playlist.m3u8');
                        break;
                    case "video_02":
                        unityvideoplayer.src('https://streaming2.rentit.hu/vod/_definst_/FISU/FISU_HALL_VIDEOS/Magyarorszag_visszavar.mp4/playlist.m3u8');
                        break;
                    case "video_03":
                        unityvideoplayer.src('https://streaming2.rentit.hu/vod/_definst_/FISU/FISU_HALL_VIDEOS/MTU_365_06_25_120SEC_1080p_YOUTUBE.mp4/playlist.m3u8');
                        break;
                    case "video_04":
                        unityvideoplayer.src('https://streaming2.rentit.hu/vod/_definst_/FISU/FISU_HALL_VIDEOS/WOW_HUNGARY_330sec.mp4/playlist.m3u8');
                        break;
                    case "video_05":
                        unityvideoplayer.src('https://streaming2.rentit.hu/vod/_definst_/FISU/FISU_HALL_VIDEOS/FISU_Rebrand_Video.mp4/playlist.m3u8');
                        break;
                }
                unityvideoplayer.play();
                $('#unity3d-details .video_viewer').css("display", "flex").hide().fadeIn(400, function () {
                    $("#unity3d-details .reactivate").show();
                });
            } else {
                $("#unity3d-details .open_new_window").show();
                posterToOpen = data.door;
            }
        }
    }

    function call_exhibitor(room) {
        unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');

        checkTrueConfRoom(room, function(response){
            var has_exhibitor = false;
            var participants = response.participants;
            for (var i = 0; i < participants.length; i++) {
                if (participants[i].id.indexOf("exhibitor") > -1) {
                    has_exhibitor = true;
                    break;
                }
            }

            if (has_exhibitor) {
                closeTrueConfCall("3d");
                openTrueConfCall(response.room, 'exhibitor');
                $("#unity3d-details .reactivate").hide();
            } else {
                Modal.alert(Language.getItem("exhibitor-not-available"), function(){
                    unityInstance.SendMessage('Logic', 'ContinueGame');
                });
            }
        });
    }

    function checkTrueConfRoom(room, callback) {
        $.ajax({
            method: 'post',
            url: 'api/',
            dataType: 'json',
            data: {
                do: 'checkTrueConfRoom',
                room: room,
                server: 'exhibitor'
            },
            success: function (response) {
                callback(response);
            }
        });
    }

    function exited(data) {
        console.log('Exited', data);
        //$('#unity3d .poster_viewer').hide();
    }

    function start(data) {
        console.log('start', data);

        if(data.current_scene_id !== currentSceneID){
            unityInstance.SendMessage('Logic', 'LoadScene', currentSceneID);
        }

        checkCoffee();

        loadPosters();

        $("#unity3d-details .loader").fadeOut(400, function () {
            $("#unity3d-details .help").addClass("not-shown");
            $("#unity3d-details .tutorial").fadeIn(400);
        });
    }

    function mouseUp(data) {
        console.log('mouseUp', data);
    }

    function loadPosters() {
        if (currentSceneID === "poster_hall") {
            var filtered_countries = AppData.countries.filter(function (item) {
                return parseInt(item.is_active) === 1;
            }).map(function (item) {
                return item.iso.toLowerCase();
            });
            unityInstance.SendMessage('Logic', 'HandleData', 'fisu_hall:,'+filtered_countries.join(","));
        }
    }

    function keypress(data) {
        if (data.key == "esc" && data.additionalData != "") {

            var properties = data.additionalData.split(',');
            var additionalData = {};
            properties.forEach(function(property) {
                var tup = property.split(':');
                additionalData[tup[0]] = tup[1];
            });

            if (additionalData.showexit == 1) {
                $('#unity3d-details .end3d').show();
            }

            keypress_additionalData = data.additionalData;
        }
    }

    function Build() {

    }

    $(document).on('click', '#unity3d-details #unityContainer', function (e) {
        //showDetails($(this).attr("data-id"));
        unityInstance.SendMessage('Logic', 'ContinueGame');
    });

    $(document).on('click', '#unity3d-details .close_poster', function (e) {
        $('#unity3d-details .end3d').hide();
        $('#unity3d-details #poster_viewer').attr('src', '');
        $('#unity3d-details .poster_viewer').hide();
        //unityInstance.SendMessage('Logic', 'ContinueGame');
    });

    $(document).on('click', '#unity3d-details .close_stand', function (e) {
        //showDetails($(this).attr("data-id"));
        $('#unity3d-details .end3d').hide();
        $('#unity3d-details #stand_viewer').attr('src', '');
        $('#unity3d-details .stand_viewer').hide();
        //unityInstance.SendMessage('Logic', 'ContinueGame');
    });

    $(document).on('click', '#unity3d-details .close_video', function (e) {
        //showDetails($(this).attr("data-id"));
        $('#unity3d-details .end3d').hide();
        unityvideoplayer.pause();
        $('#unity3d-details .video_viewer').hide();
        //unityInstance.SendMessage('Logic', 'ContinueGame');
    });

    $(document).on('pointerlockchange', function(e){
        console.log("pointerlock change", keypress_additionalData, document.pointerLockElement);
        if (document.pointerLockElement == null) {
            var data = "esc";
            console.log(keypress_additionalData);
            if (keypress_additionalData != "") {
                data += ";"+keypress_additionalData;
            }
            unityInstance.SendMessage('Logic', 'Keypress', data);
            keypress_additionalData = "showexit:1";
        }
    });

    $(document).on('click', '#unity3d-details #end-tutorial', function() {
        unityInstance.SendMessage('Logic', 'ContinueGame');
        //hideTutorial();
        //showHelp();
    });

    $(document).on('click', '#unity3d-details #next-tutorial', function() {
        var dot = $('#unity3d-details .dots .dot:not(.completed)');
        if (dot.length > 0) {
            let nextIndex = dot.attr('data-index');
            showTutorialPage(parseInt(nextIndex));
        } else {
            unityInstance.SendMessage('Logic', 'ContinueGame');
            $("#unity3d-details #unityContainer canvas").get(0).requestPointerLock();
            $('#unity3d-details .tutorial').hide();
            $("#unity3d-details .help").removeClass("not-shown");
        }
    });

    $(document).on('click', '#unity3d-details #end-tutorial', function() {
        unityInstance.SendMessage('Logic', 'ContinueGame');
        $("#unity3d-details #unityContainer canvas").get(0).requestPointerLock();
        $('#unity3d-details .tutorial').hide();
        $("#unity3d-details .help").removeClass("not-shown");
    });

    $(document).on('click', '#unity3d-details .dots .dot', function() {
        var index = parseInt($(this).attr('data-index'));
        showTutorialPage(index);
    });

    $(document).on('click', '#unity3d-details #cancel-logout', function() {
        unityInstance.SendMessage('Logic', 'ContinueGame');
        $('#unity3d-details .end3d').hide();
    });

    $(document).on('click', '#unity3d-details #accept-logout', function() {
        unityInstance.SendMessage('Logic', 'ReleaseKeyBoard');
        $('#unity3d-details .end3d').hide();
        $("#unity3d-details").hide();
        from3d = false;
    });

    $(document).on('click', '#unity3d-details #cancel-open', function() {
        unityInstance.SendMessage('Logic', 'ContinueGame');
        $('#unity3d-details .open_new_window').hide();
    });

    $(document).on('click', '#unity3d-details #accept-open', function() {
        unityInstance.SendMessage('Logic', 'Keypress', 'esc;showexit:0');
        $('#unity3d-details .open_new_window').hide();
        /*var url = AppData.settings.infopanel_url+"/info/kiosk/visit/"+posterToOpen;
        url += "/"+encodeURIComponent(btoa(AppData.profile.id));
        window.open(url);*/

        $('#unity3d-details #poster_viewer').attr('src', 'upload/'+posterToOpen+'.pdf');
        $('#unity3d-details .poster_viewer').show();

        $('#unity3d-details .reactivate').show();
    });

    $(document).on('click', '#unity3d-details .reactivate', function() {
        unityInstance.SendMessage('Logic', 'ContinueGame');
        $("#unity3d-details #unityContainer canvas").get(0).requestPointerLock();
        $('#unity3d-details .reactivate').hide();
    });

    function showTutorialPage(selectedIndex) {
        $('#unity3d-details .tutorial .slide').each(function(index, item) {
            $(this).removeClass('out-left');
            $(this).removeClass('out-right');
            if (index < selectedIndex) {
                $(this).addClass('out-left');
            } else if (index > selectedIndex) {
                $(this).addClass('out-right');
            }
        });
        var dots = $('#unity3d-details .dots .dot');
        dots.each(function(index, item) {
            $(this).removeClass('completed');
            if (index <= selectedIndex) {
                $(this).addClass('completed');
            }
        });
        var buttonContainer = $('#unity3d-details #next-tutorial');
        if (selectedIndex === dots.length - 1) {
            buttonContainer.html('Enter');
        } else {
            buttonContainer.html('Next');
        }
    }

    $(document).on('click', '#unity3d .unity3dList .item', function(e) {
        //Layout.hide("unity3d");
        load3DPlayer(this.dataset.id);
    });

    function showDetails(id) {
        var u3did = AppData.unity3d.filter(function(e) {
            return e.id === id;
        })[0];
        load3DPlayer(u3did.u3did);
    }

    function openTextChat(uid) {
        unityInstance.SendMessage('Logic', 'ReleaseKeyBoard');
        Chat.showFloatingChat(uid);
    }

    /* ----- Init --------------------------------------------------------------------------------------------------- */
    function BuildFromSource() {

        $.post('api/', {
            do: 'getUnity3d'
        }, function (data) {
            AppData.unity3d = data;
            Init();
        });
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        Build: Build,
        showDetails: showDetails,
        entered: entered,
        exited: exited,
        start: start,
        mouseUp: mouseUp,
        keypress: keypress,
        load3DPlayer: load3DPlayer,
        callbackHandler: callbackHandler,
        call_exhibitor: call_exhibitor,
        openTextChat: openTextChat
    }
})();
