/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential
  */
var Modulsettings = (function () {
    'use strict';

    /* ----- Init Settings -------------------------------------------------------------------------------------------- */
    function Init() {

        BuildFromSource();
        return true;
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getModulSettings'
        }, function (data) {
            AppData.modulsettings = data;
            Docs.renderList();
            Profile.Init();

            ToggleRedAlert();
            Agenda.Build();
            Agenda.showFavorite("sub");
            Agenda.showFavorite();
            Agenda.toggleRequestSpeakerGotoLink();
            Agenda.toggleItemArrows();

            Speakers.toggleRequestBusinessCard();
            Speakers.toggleRequestSpeakerGotoLink();
            Speakers.toggleSocial();
            Speakers.renderList();
            Speakers.renderDetails();
            Attendees.toggleRequestBusinessCard();
            Attendees.toggleSocial();
            Attendees.renderList();

            Gallery.renderDetails();
            Photos.renderList();

            Broadcast.setRunawayDatas();
            Broadcast.setBroadcastPoster();
            Broadcast.BuildBroadcast();
            Broadcast.showReactions();
            Broadcast.showTranslator();
            Broadcast.showChatButton();
            Broadcast.showTexttracks();
            Trueconf.showTranslator();

            Translator.toggleRelayBtns();

            Messageboard.showMsgBoard();
            if (!AppData.modulsettings.attentionchecker) {
                Broadcast.disableAttentionGuard();
            } else {
                Broadcast.enableAttentionGuard();
            }
            Issues.renderList();
            Issues.renderDetails();

            if (AppData.modulsettings.showvideochat) {
                $("#chat-details .video-chat").show();
            } else {
                $("#chat-details .video-chat").hide();
            }

            Chat.refresh();

            let $layouts = $('div.main');
            /* Set Title*/
            $layouts.find('.header-primary .data-title').text(AppData.modulsettings.title[localState.lang]);

            /* Set SubTitle */
            $layouts.find('.header-primary .data-subtitle').text(AppData.modulsettings.subTitle[localState.lang]);

            if (AppData.settings.foregroundtype == "text") {
                $layouts.filter('#landing').find('.hero').html(AppData.modulsettings.fgtext[localState.lang]);
            }

            Issues.maxCharacterCount();

            Bookings.BuildFromSourceClient();

            Update();

            data = null;
        });
    }

    function ToggleRedAlert() {
        $("#landing").find('.open-main.broadcast').toggleClass('blinkingicon', AppData.modulsettings.showredalert);
        $("#landing").find('#default_nav .open-main.broadcast').toggleClass('color-primary', !AppData.modulsettings.showredalert);

        if (AppData.modulsettings.showredalert) {
            $("#landing").find('.open-main.broadcast').css("color", AppData.settings.colorPrimary);
        } else {
            $("#landing").find('.open-main.broadcast').css("color", "");
        }

    }

    function Update() {
        if (AppData.modulsettings.cdown_showonclient == false && AppData.modulsettings.bc_landingbutton == false) {
            $("#landing .countdown_bc").hide();
        } else {
            $("#landing .countdown_bc").show();
        }

        showCountDown(AppData.modulsettings.cdown_showonclient, AppData.modulsettings.cdown_clientpos);

        if (AppData.modulsettings.bc_landingbutton) {
            $("#landing .countdown_bc .bc_button").show();
        } else {
            $("#landing .countdown_bc .bc_button").hide();
        }

        if (AppData.profile.group == "translator" && AppData.modulsettings.translator_disallow_mic) {
            $("#landing #trueconf_iframe").attr("allow", "microphone 'none'; camera; autoplay; display-capture");
        } else {
            $("#landing #trueconf_iframe").attr("allow", "microphone; camera; autoplay; display-capture");
        }

        if (["top", "bottom", "left"].indexOf(AppData.modulsettings.emoji_pos) > -1) {
            $(".stream_reactions").removeClass("top bottom left").addClass(AppData.modulsettings.emoji_pos);
        } else {
            $(".stream_reactions").removeClass("top bottom left").addClass("bottom");
        }

        if (AppData.modulsettings.show_quickmenu) {
            $("#minimal_nav").appendTo($(".bodycontent"));
            if (localState.layout.length > 0) {
                $("#minimal_nav").addClass("topAnimationDown");
            }
            Navigation.setMinimalisticMenu();
        }

        $('nav#minimal_nav').toggle(AppData.modulsettings.show_quickmenu);

        if ('landingbutton_bgcolor' in AppData.modulsettings && 'landingbutton_textcolor' in AppData.modulsettings) {
            var inlineStyle = '.landingbutton { color: ' + AppData.modulsettings.landingbutton_textcolor + ' !important; background-color: ' + AppData.modulsettings.landingbutton_bgcolor + ' !important;}';
            inlineStyle += '.play-btn-cont { color: ' + AppData.modulsettings.landingbutton_bgcolor + ' !important;}';
            $('head').append('<style>' + inlineStyle + '</style>');
        }
    }

    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        Update: Update
    };
})();
